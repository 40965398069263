import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ObjectType } from "../ReservationI";
import { S25ItemI } from "../../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-ev-colander")
@Component({
    selector: "s25-ng-ev-colander",
    template: `
        @if (init && itemNames?.length > 1) {
            <div class="spacer-bottom">
                <div class="s25-panel-head ev-prefs-wrapper">
                    <span
                        class="{{
                            type === 'location'
                                ? 's25-item-type-icon-holder-spaces'
                                : 's25-item-type-icon-holder-resources'
                        }}"
                    >
                        <span
                            class="{{ type === 'location' ? 'icon-space' : 'icon-resource' }} s25item-type-icon"
                        ></span>
                    </span>
                    <span class="ngBold">Filter By {{ type === "location" ? "Locations" : "Resources" }}</span>
                    @if (!isExpand) {
                        <span class="floatRight" (click)="expandToggle(true)">
                            <svg class="c-svgIcon" role="button">
                                <title>Expand</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-up"
                                ></use>
                            </svg>
                        </span>
                    }
                    @if (isExpand) {
                        <span class="floatRight" (click)="expandToggle(false)">
                            <svg class="c-svgIcon" role="button">
                                <title>Collape</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                                ></use>
                            </svg>
                        </span>
                    }
                </div>
                @if (isExpand) {
                    <div class="evdo-panel first">
                        <span>
                            <s25-ng-checkbox [(modelValue)]="isSelectedAll" (modelValueChange)="selectAll()"
                                >Select All</s25-ng-checkbox
                            >
                        </span>
                        <span class="floatRight radio-wrapper">
                            <s25-ng-radio
                                [(modelValue)]="filterType"
                                [value]="'any'"
                                [name]="type"
                                (modelValueChange)="filter()"
                                >Any</s25-ng-radio
                            >
                            <s25-ng-radio
                                [(modelValue)]="filterType"
                                [value]="'all'"
                                [name]="type"
                                (modelValueChange)="filter()"
                                >All</s25-ng-radio
                            >
                        </span>
                    </div>
                    <div class="evdo-panel">
                        @for (d of itemNames; track d) {
                            <div>
                                <s25-ng-checkbox [modelValue]="isChecked(d)" (modelValueChange)="onCheckBox($event, d)">
                                    {{ d }}</s25-ng-checkbox
                                >
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: [
        `
            ::ng-deep .floatRight {
                float: right !important;
            }

            ::ng-deep .s25-panel-head {
                background-color: #f3f3f3;
                font-size: 0.875rem;
            }

            ::ng-deep .evdo-panel {
                border-inline: 1px solid rgba(0, 0, 0, 0.15);
                background-color: #fff;
                padding: 10px;
            }

            ::ng-deep input[type="checkbox"] {
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                background-color: #fafaf9;
            }

            .spacer-bottom {
                margin-bottom: 20px;

                .first {
                    display: flex;
                    justify-content: space-between;

                    .radio-wrapper {
                        display: flex;
                        gap: 5px;
                    }
                }
            }
        `,
    ],
})
export class S25EventColanderComponent implements OnInit {
    @Input() data?: S25ItemI[];
    @Input() type: ObjectType;
    @Output() modelValueChange = new EventEmitter<{ filterType: string; itemList: string[] }>();

    isExpand: boolean = true;
    init: boolean = false;
    itemList: string[];
    isSelectedAll: boolean = true;
    filterType: string = "any";
    itemNames?: string[] = [];

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        setTimeout(() => {
            this.itemNames = [...new Set(this.data.map((item: any) => item.itemName))].sort();
            this.itemList = this.itemNames;
            this.init = true;
            this.cd.detectChanges();
        }, 3600);
    }

    expandToggle(e: boolean) {
        this.isExpand = e;
        this.cd.detectChanges();
    }

    selectAll() {
        if (this.isSelectedAll) {
            this.itemList = this.itemNames;
        } else {
            this.itemList = [];
        }

        // if no itemList selected,  nothing till at lease one box checked
        if (this.itemList.length > 0)
            this.modelValueChange.emit({ itemList: this.itemList, filterType: this.filterType });
        this.cd.detectChanges();
    }

    filter() {
        this.modelValueChange.emit({ itemList: this.itemList, filterType: this.filterType });
    }

    isChecked(name: string): boolean {
        return this.itemList.includes(name);
    }

    onCheckBox(checked: boolean, name?: string) {
        if (!checked) this.isSelectedAll = false;
        if (checked) {
            let find = this.itemList.filter((i: string) => {
                return i === name;
            });
            find.length === 0 ? this.itemList.push(name) : "";
        } else {
            this.itemList = this.itemList.filter((i: string) => {
                return i !== name;
            });
        }
        if (this.itemList.length > 0)
            this.modelValueChange.emit({ itemList: this.itemList, filterType: this.filterType });
    }
}
