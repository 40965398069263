import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { Debounce } from "../../../../decorators/debounce.decorator";

/**
 * Displays gear icon, lets the user select between 'seperated' and 'merged view'. migration of evdo-menu
 * OTHER NOTES:
 * Commented out "Delete This Segment"  event micro service current not support this feature
 * ACCL-1752 getting very large, don't want adding any new functionality
 *
 */
@Component({
    selector: "s25-ng-occ-menu",
    template: ` @if (multiProfiles) {
            <div class="occ-menu" (click)="onViewChange()">
                {{ isSeparatedView ? "Merged View" : "Separated View" }}
            </div>
        }
        @if (canEdit) {
            <div class="occ-menu" (click)="addSegment()">Add a New Segment</div>
        }

        @if (addNewSegment) {
            <div class="date-time-section">
                <s25-ng-editable-start-end-datetime
                    [startDatetime]="startDate"
                    (startDatetimeChange)="onDateTimeChange($event, 'start')"
                    [endDatetime]="endDate"
                    (endDatetimeChange)="onDateTimeChange($event, 'end')"
                    [noTimeUpdated]="true"
                >
                </s25-ng-editable-start-end-datetime>
                <button class="aw-button aw-button--outline c-margin-top--half" (click)="onSubmit(true)">Submit</button>
            </div>
        }

        @if (canEdit && isSeparatedView) {
            <!--  <div class="occ-menu" (click)="copySegment()">Copy This Segment</div> -->
            @if (multiProfiles) {
                <div class="occ-menu" (click)="deleteSegment()">Delete This Segment</div>
            }
        }`,
    styles: [
        `
            .occ-menu {
                padding: 15px;
                font-size: 16px !important;
                font-weight: 600;
                color: #555;
                cursor: pointer;
            }

            .nm-party--on {
                .occ-menu {
                    color: #fff;
                }

                .c-start-end-datetime-picker--wrapper {
                    box-shadow: unset !important;
                }
            }

            .date-time-section {
                padding: 10px 20px 20px 20px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvOccMenuComponent {
    @Input() isSeparatedView: boolean;
    @Input() profileId?: number;
    @Input() canEdit?: boolean = false;
    @Input() multiProfiles?: boolean = false;
    @Input() startDate?: Date;
    @Input() endDate?: Date;
    @Output() modelValueChange: EventEmitter<EvOccMenuOutput> = new EventEmitter<EvOccMenuOutput>();

    init: boolean = false;
    addNewSegment: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.init = true;
        this.cd.detectChanges();
    }

    @Debounce(300) // Avoid double calls
    copySegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: true,
            data: { profileId: this.profileId, startDate: this.startDate, endDate: this.endDate },
        });
    }

    //go to seperated view and indicate that a new segment is added
    addSegment() {
        this.addNewSegment = true;
        this.cd.detectChanges();
    }

    @Debounce(300) // Avoid double calls
    onSubmit(addNew: boolean) {
        if (addNew) {
            const data = {
                startDate: this.startDate,
                endDate: this.endDate,
            };
            this.isSeparatedView = true;
            this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: true, data: data });
        }
    }

    onDateTimeChange(newDate: Date, text: "start" | "end") {
        text === "start" ? (this.startDate = newDate) : (this.endDate = newDate);
    }

    onViewChange() {
        this.isSeparatedView = !this.isSeparatedView;
        this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: false });
    }

    @Debounce(300) // Avoid double calls
    deleteSegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: false,
            data: { profileId: this.profileId },
            isDelete: true,
        });
    }

    refresh() {
        this.init = false;
        this.cd.detectChanges();
        this.init = true;
        this.cd.detectChanges();
    }
}

export type EvOccMenuOutput = {
    isSeparatedView: boolean;
    data?: {
        profileId?: number;
        startDate?: Date;
        endDate?: Date;
    };
    addNew: boolean;
    isDelete?: boolean;
};
